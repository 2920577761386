import * as styles from './index.module.scss'

import { graphql } from 'gatsby'
import React from "react"
import Comments from '../../components/comments'
import Layout from '../../components/layout/index'
import PostHeader from '../../components/post/header'
import Promo from '../../components/promo'
import SectionsRenderer from '../../components/sections/renderer'
import Seo from '../../components/seo'
import { getField } from '../../helper.js'
import { pathTo } from '../../routes'
import { translate } from '../../translations'

const Post = ({ data, pageContext: { locale = 'de', pathDe, pathEn } }) => {
  const { post, post: { vgWortId }, aboutUsPage, morePosts } = data
  const title = getField(post, 'title', locale)
  const teaser = getField(post, 'teaser', locale)
  const metaTitle = getField(post, 'metaTitle', locale)
  const metaDescription = getField(post, 'metaDescription', locale)
  const keywords = getField(post, 'keywords', locale)
  const sharingImage = getField(post.coverImage, 'file', locale)?.src
  const visual = getField(post.visual, "file", locale)
  const visualMobile = getField(post.visualMobile, "file", locale)
  const visualMedium = getField(post.visualMedium, "file", locale)
  const visualLarge = getField(post.visualLarge, "file", locale)

  const localizedUrls = {
    de: pathDe,
    en: pathEn
  }

  return (
    <>
      <Seo
        locale={locale}
        localizedUrls={localizedUrls}
        title={metaTitle}
        sharingTitle={title}
        sharingDescription={teaser}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        keywords={keywords}
        image={sharingImage}
      />
      <Layout locale={locale} localizedUrls={localizedUrls}>
        {vgWortId && <img src={`http://vg09.met.vgwort.de/na/${vgWortId}`} height="1" width="1" border="0" />}
        <PostHeader
          title={title}
          author={post.author}
          aboutUsPage={aboutUsPage}
          series={post?.series}
          visual={visual}
          visualMobile={visualMobile}
          visualMedium={visualMedium}
          visualLarge={visualLarge}
          advertisement={post?.advertisement}
          locale={locale}
        />
        <SectionsRenderer sections={post?.sections} locale={locale} />
        <Comments url={pathTo({ ...post, locale })} title={title} />
        {(post.series &&
          <div className={styles.promo}>
            <Promo
              titleDe={`${translate('recipe.more', locale)} ${post.series.titleDe}`}
              titleEn={`${translate('recipe.more', locale)} ${post.series.titleEn}`}
              items={morePosts.edges.map(edge => edge.node)}
              locale={locale}
            />
          </div>
        )}
      </Layout>
    </>
  )
}

export default Post

export const query = graphql`
  query PostQuery($id: String!, $seriesId: String) {
    post: cmsPost(id: {eq: $id}) {
      __typename
      titleDe
      titleEn
      slugDe
      slugEn
      series {
        __typename
        slugDe
        slugEn
        titleDe
        titleEn
      }
      sections {
        __typename
        ...Banner
        ...Photos
        ...RecipeCard
        ...Text
        ...YoutubePlayer
        ...PostAndRecipeGrid
      }
      metaTitleDe
      metaTitleEn
      metaDescriptionDe
      metaDescriptionEn
      keywordsDe
      keywordsEn
      coverImage {
        fileDe {
          src
        }
        fileEn {
          src
        }
      }
      author {
        name: displayName
      }
      advertisement
      vgWortId
      ...PostHeaderImages
    }

    aboutUsPage: cmsPage(id: {eq: "Page:UGFnZS0xNDQ="}) {
      __typename
      slugDe
      slugEn
    }

    morePosts: allCmsPost(limit: 8, filter: {
      id: {
        ne: $id
      },
      series: {
        id: {
          eq: $seriesId
        }
      }
      slugDe: {ne: null} }
      ) {
      edges {
        node {
          ...PromoPost
        }
      }
    }
  }
`

// TODO: post header series is missing
// TODO: promo is missing due to series missing
